.toast-container {
  width: 100%;
  z-index: 9999;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
  display: flex;
  position: absolute;
  top: 0;
}

.toast-container .toast {
  width: 80%;
  background-color: var(--base-colour);
  pointer-events: all;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1rem;
  transition: all .5s;
  position: relative;
  box-shadow: 0 20px 20px -5px #0000001a;
}

.toast-container .toast.appearing, .toast-container .toast.removing {
  transform: translateY(-50vh);
}

.toast-container .toast.removing.phase-two {
  height: 0;
  border: none;
  margin: 0;
  padding: 0;
}

.toast-container .toast.error {
  background-color: #fcede9;
  border-color: #f8dbd3;
}

.toast-container .toast.warning {
  background-color: #fef7eb;
  border-color: #fdecce;
}

.toast-container .toast.info {
  background-color: #e5eefa;
  border-color: #d4e3f7;
}

.toast-container .toast .toast-title {
  font-size: 16px;
  font-weight: 700;
}

.toast-container .toast .content {
  font-size: 12px;
}

/*# sourceMappingURL=app.ec49059f.css.map */
