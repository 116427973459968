.toast-container {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 4rem;
	z-index: 9999;
	pointer-events: none;

	.toast {
		position: relative;
		width: 80%;
		padding: 1rem;
		border-radius: 10px;
		border: 1px solid;
		background-color: var(--base-colour);
		box-shadow: 0px 20px 20px -5px rgba(0, 0, 0, 0.1);
		transition: 500ms ease;
		margin-bottom: 1rem;
		pointer-events: all;

		&.appearing {
			transform: translateY(-50vh);
		}

		&.removing {
			transform: translateY(-50vh);

			&.phase-two {
				height: 0;
				padding: 0;
				margin: 0;
				border: none;
			}
		}

		&.error {
			background-color: hsl(13, 74%, 95%);
			border-color: hsl(13, 74%, 90%);
		}

		&.warning {
			background-color: hsl(38, 94%, 96%);
			border-color: hsl(38, 94%, 90%);
		}

		&.info {
			background-color: hsl(214, 68%, 94%);
			border-color: hsl(214, 68%, 90%);
		}

		.toast-title {
			font-size: 16px;
			font-weight: 700;
		}

		.content {
			font-size: 12px;
		}
	}
}
